import React from "react"
import { Link } from "../../services/routes" //  "gatsby";

export default ({title, link, sourceLink="/", sourceName="Home"}) => (
	<section className="banner_area" style={{minHeight:"200px"}}>
		<div className="banner_inner d-flex align-items-center" style={{minHeight:"200px"}}>
			<div className="container">
				<div className="banner_content text-center">
					<h2>{title}</h2>
					{/*
					<div className="page_link">
						<Link to={sourceLink}>{sourceName}</Link>
						<Link to={link}>{title}</Link>
					</div>
					*/}
				</div>
			</div>
		</div>
	</section>
);
