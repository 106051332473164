import React from "react"

import Banner from "./banner"
import routes from "../../services/routes"

export default () => (
	<>
		<Banner title="Contact Us" link={routes.contact} />
		<div style={{backgroundColor:"white", margin:"50px", padding:"15px", textAlign:"center"}}>
			<h4> </h4>We'd love to hear from you! Please reach out to us at{' '}
			<a href="mailto:contact@scratchwork.io">contact@scratchwork.io</a>
		</div>
	</>
);
